
.oc-c-shopping-cart-popup {
  padding: 16px;
  height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: 100%;

  &-popup {
    background-color: var(--oc-s-b-c) !important;
  }

  &-title {
    font-size: 18px;
    font-weight: 700;
  }

  &-subtitle {
    margin-top: 8px;
    font-size: 14px;
    color: var(--oc-t-g-c);
  }

  &-menus {
    padding: 0 !important;
  }
  &-content {
    overflow-y: auto;
    margin-top: 16px;
    margin-bottom: 60px;
    padding: 16px;
    border-radius: 16px;
    background-color: var(--oc-b-c);
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-statistics {
    border-top: 1px solid var(--oc-bd-c);
    padding: 16px;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &.total {
        font-weight: 700;
      }
    }
  }

  .rv-button {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 16px;
  }
}
