.oc-c-business {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  background-color: var(--oc-s-b-c);
  padding: 16px 0;

  &-title {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--oc-p-c-20);
    background-color: var(--oc-b-c);
    margin-right: 8px;
  }
}