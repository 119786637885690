.oc-c-tabs {
  &-content {
    width: 100%;
    overflow-x: auto;
    display: flex;
    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      color: var(--oc-t-g-c);
      font-size: 14px;
      font-weight: 500;
      padding: 5px 10px;
      white-space: nowrap;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &.active {
        background-color: var(--oc-p-c);
        color: var(--oc-t-l-c);
        border-radius: 200px;
        font-weight: 700;
      }
    }
  }
  &-items {
    margin-top: 12px;
    display: flex;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    &-item {
      padding: 8px 16px;
      white-space: nowrap;
      &.active {
        background-color: var(--oc-b-c);
        border-radius: 16px 16px 0 0;
      }
    }
  }
}