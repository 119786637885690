.oc-p-menu-list {
  background-color: var(--oc-s-b-c);
  padding-bottom: 88px;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
  
  &-content {
    margin: 0 16px;
  }
}