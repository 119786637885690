.oc-c-shopping-cart {
  position: fixed;
  left: 0%;
  right: 0%;
  bottom: 18px;
  z-index: 1999;
  height: auto;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &-content {
    background-color: var(--oc-b-c);
    margin: 0 16px;
    height: 50px;
    display: flex;
    border-radius: 16px;
    overflow: hidden;
    &-left {
      flex: 1;
      padding: 8px 16px;
      display: flex;
      align-items: center;
    }
    &-right {
      width: 100px;
      background-color: var(--oc-p-c);
      font-weight: 700;
      color: var(--oc-t-l-c);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-total {
    margin-left: 24px;
    font-weight: 600;
  }

  &-icon {
    &-quanity {
      position: absolute;
      left: 52px;
      top: 4px;
      right: 0;
      bottom: 0;
      display: flex;
      width: 18px;
      height: 18px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: var(--oc-p-c);
      color: var(--oc-t-l-c);
      font-weight: 500;
      font-size: 10px;
    }
  }
}