
.oc-c-toast {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(#000000, 0.1);
  z-index: 10000;
  backdrop-filter: blur(1px);
  &-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--oc-b-c);
    padding: 24px;
    border-radius: 12px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-message {
    text-align: center;
    margin-top: 16px;
    font-size: 14px;
  }
}