.oc-c-dining-methods-panel {
  padding: 12px 16px;
  background: var(--oc-b-c);
  border-radius: 16px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  &-title {
    font-size: 14px;
    font-weight: 600;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &-btn {
    color: var(--oc-p-c);
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    align-self: flex-start;
    margin-left: 4px;
  }
  &-content {
    flex: 1;
    font-size: 14px;
    color: var(--oc-t-g-c);
    &-title {
      font-weight: 600;
      color: var(--oc-t-d-c);
    }
    &-street {
      margin: 4px 0;
    }

    &-details {
      margin-top: 6px;
    }
  }
}