.oc-c-header {
  // background-image: url(../../../public/images/header-bg.png);
  // background-color: var(--oc-p-c);
  height: 96px;
  padding: 0 16px;
  position: relative;

  &-title, &-language {
    background-color: var(--oc-b-c);
    color: var(--oc-t-d-c);
    padding: 6px 8px;
    border-radius: 6px;
    font-weight: 500;
    font-size: 13px;
    position: absolute;
  }

  &-title {
    left: 16px;
    top: 16px;
  }

  &-language {
    display: flex;
    align-items: center;
    right: 16px;
    top: 16px;
    img {
      margin-right: 6px;
    }
  }

  .rv-dropdown-menu__bar {
    background-color: var(--oc-b-c);
    box-shadow: none;
  }
}