.oc-c-business-info {
  margin-top: 16px;
  &-content {
    background-color: var(--oc-b-c);
    border-radius: 16px;
    overflow: hidden;
    &-title {
      padding: 12px 16px;
      background-color: var(--oc-p-c-20);
      font-weight: 500;
    }
    &-wrapper {
      padding: 16px;
    }

    &-item {
      line-height: 20px;
      margin-bottom: 10px;

      .label {
        color: var( --oc-t-g-c);
        font-weight: 500;
        margin-bottom: 4px;
      }
    }
    &-details {
      padding-top: 10px;
      border-top: 1px solid #EEF0F1;
    }
  }
}