.oc-c-dining-methods {
  padding: 16px;
  height: inherit;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  &-title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
  }

  &-choose {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
    margin-bottom: 16px;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--oc-i-c);
      border: 1px solid var(--oc-t-g-c);
      border-radius: 8px;
      padding: 8px 0;
      &.active {
        color: var(--oc-p-c);
        background-color: var(--oc-p-c-10);
        border: 1px solid var(--oc-p-c-60);
      }
      &-title {
        font-size: 12px;
        margin-top: 4px;
        font-weight: 500;
      }
    }
  }

  &-content {
    flex: 1;
    overflow-y: scroll;
  }

  &-input {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    padding: 0 16px;
    background: var(--oc-s-b-c);
    color: var(--oc-t-d-c);
    font-weight: 500;
    line-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &.placeholder {
      color: var(--rv-gray-5);
    }
  }

  &-delivery {
    height: 100%;
    position: relative;
    overflow: auto;
    .rv-form {
      height: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      &-content {
        padding-bottom: 54px;
      }
    }

    &-list {
      &-item {
        background: var(--oc-s-b-c);
        border-radius: 16px;
        padding: 16px;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        color: var(--oc-t-d-c);
        &-details {
          &>div:first-child {
            font-weight: 600;
            margin-bottom: 12px;
          }
          &>div:nth-child(2) {
            margin-bottom: 4px;
          }
        }
        &-actions {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }

    &-add {
      background-color: var(--oc-s-b-c);
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding: 16px;
      color: var(--oc-p-c);
      font-size: 14px;
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
      }
    }

    &-noData {
      margin-top: 32px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .tips {
        margin-top: 32px;
        font-weight: 500;
        color: var(--oc-t-g-c);
      }
    }
    .rv-button {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    &-phone {
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: 8px;
      background: var(--oc-s-b-c);
      padding-left: 16px;
      &-number {
        font-weight: 500;
        color: var(--oc-t-d-c);
        margin: 0 4px;
      }
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-search {
    width: 100%;
    &-label {
      color: var(--oc-t-g-c);
      font-weight: 500;
      margin-bottom: 4px;
      &::after {
        position: absolute;
        color: var(--oc-e-c);
        font-size: 14px;
        content: '*';
      }
    }
    &-content {
      margin-top: 12px;
      background: var(--oc-s-b-c);
      border-radius: 8px;
      &.active {
        padding: 12px 16px;
      }
      &-item {
        display: flex;
        align-items: center;
        padding: 10px;
        font-weight: 500;
        &-right {
          margin-left: 16px;
          &-label {
            color: var(--oc-t-d-c);
          }
          &-details {
            color: var(--oc-t-g-c);
          }
        }
      }
    }
  }

  &-takeaway {
    height: 100%;
    position: relative;
    overflow: auto;
    &-selectTime {

      &-title {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 18px;
        .value {
          font-size: 12px;
          color: var(--oc-p-c);
        }
      }
      .rv-picker {
        background-color: var(--oc-s-b-c);
        border-radius: 6px;
      }
    }

    .rv-button {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  &-dineIn {
    &-title {
      font-weight: 500;
      color: var(--oc-t-g-c);
      margin-bottom: 12px;
    }
    &-content {
      width: 100%;
      height: 0;
      padding-bottom: 100%;
      position: relative;
      border-radius: 32px;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.5);
      #reader {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      &-msg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--oc-b-c);
        border-radius: 12px;
        padding: 24px;
        width: 200px;
        .value {
          text-align: center;
          line-height: 22px;
          margin-top: 16px;
          color: var(--oc-e-c);
        }
      }
    }
  }
}