
@font-face {
  font-family: 'Outfit';
  font-weight: normal;
  font-style: normal;
  src: url('../public/font/Outfit.woff2') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 500;
  src: url('../public/font/Outfit-Medium.woff') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 600;
  src: url('../public/font/Outfit-Medium.woff') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  src: url('../public/font/Outfit-Bold.woff') format('truetype');
}

@oc-primary-color: #0094FF;
@oc-background-system-color: #F5F5F5;
@oc-background-color: #FFFFFF;
@oc-border-radius: 16px;
@oc-text-dark-color: #000000;
@oc-text-light-color: #FFFFFF;
@oc-text-grey-color: #8E8E93;
@oc-border-color: #333333;
@oc-icon-color: #999999;
@oc-error-color: #FC2E01;

:root {
  --oc-p-c: @oc-primary-color;
  --oc-s-b-c: @oc-background-system-color;
  --oc-b-c: @oc-background-color;
  --oc-b-r: @oc-border-radius;
  --oc-t-d-c: @oc-text-dark-color;
  --oc-t-l-c: @oc-text-light-color;
  --oc-t-g-c: @oc-text-grey-color;
  --oc-i-c: @oc-icon-color;
  --oc-e-c: @oc-error-color;
  --oc-bd-c: fade(@oc-border-color, 20%);
  --oc-p-c-10: fade(@oc-primary-color, 10%);
  --oc-p-c-20: fade(@oc-primary-color, 20%);
  --oc-p-c-50: fade(@oc-primary-color, 50%);
  --oc-p-c-60: fade(@oc-primary-color, 60%);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--oc-s-b-c);
  color: var(--oc-t-d-c);
  font-family: Outfit !important;
}

*, :after, :before {
  box-sizing: border-box;
}

code {
  font-family: Outfit;
}

/* rv 主题 */
.rv-button--primary {
  background: var(--oc-p-c) !important;
  border: none !important;
  border-radius: 8px !important;
}

.rv-button {
  height: 50px !important;
  .rv-button__text {
    font-weight: 700;
  }
}

.rv-input {
  border-radius: 8px !important;
  background: var(--oc-s-b-c) !important;
  font-weight: 500;
  height: 50px;
  .rv-input__control {
    padding: 0 16px;
    color: var(--oc-t-d-c) !important;
  }
}

.rv-cell--required::before {
  display: none;
}

.rv-cell--required .rv-field__label::after {
  position: absolute;
  color: var(--oc-e-c);
  font-size: 14px;
  content: '*';
}

.rv-cell::after {
  display: none;
}

.rv-form-item--vertical {
  display: flex !important;
  flex-direction: column !important;
  .rv-field__label {
    width: 100% !important;
  }
}

.rv-field__label {
  color: var(--oc-t-g-c) !important;
  font-weight: 500;
}

.rv-radio__icon .rv-icon {
  border-color: var(--oc-p-c-50) !important;
}

&.rv-radio__icon--checked .rv-icon {
  background-color: inherit !important;
  color: var(--oc-p-c) !important;
  border-color: var(--oc-p-c) !important;
}

.rv-radio__label {
  margin-left: 16px !important;
}

.rv-radio-group .rv-radio:not(:last-child) {
  margin-bottom: 16px !important;
}
