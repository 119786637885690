.oc-c-menu-list {
  background-color: var(--oc-b-c);
  padding: 16px;
  border-radius: 16px;
  &.isFirst {
    border-top-left-radius: 0;
  }
  &-item {
    display: flex;
    align-items: center;
    padding: 16px 0;

    &:not(:last-child) {
      border-bottom: 1px solid var(--oc-bd-c);
    }
    &-default {
      background-color: #8E8E93;
      width: 72px;
      height: 72px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      &-img {
        width: 50px;
        height: 40px;
      }
    }
    &-left {
      flex: 1;
      display: flex;
      .img {
        width: 72px;
        height: 72px;
        margin-right: 8px;
      }
      &-details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-title {
          font-weight: 700;
          color: var(--oc-t-d-c);
          font-size: 16px;
        }
        &-describe {
          color: var(--oc-t-g-c);
          font-size: 12px;
        }
        &-price {
          color: var(--oc-t-d-c);
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
    &-right {
      display: flex;
      align-items: center;
      font-size: 14px;
      &-quantity {
        margin: 0 8px;
        line-height: 20px;
      }
    }
  }
}